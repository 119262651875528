*{
  font-family: Georgia, serif;
}
.App{
  text-align: center;
  background-color: #d5ebdc;
  
}
.info {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.myportrait{
  height: 250px;
  text-align: center;
}
.iframeCard{
  min-width: 8rem;
  max-width: 18rem;
  text-align: center;
  height: 400px;
}
.cardImg{
  height: 200px;
}
.linkToPage{
  background: none;
  border: none;
  color: inherit;
  padding: 0;
}
a {
  text-decoration: none;
}
.scrollUp{
  color:#0dcaf0;
  position: fixed;
  font-size: 3rem;
  width:100%;
  bottom: 10px;
  height: 30px;

}
/* Small Screens max width 640px */
/* @media screen and (max-width: 640px) {
  .skillsGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-auto-rows: auto;
    height: auto;
  } */
.skillsGrid img {
  height: 75px;
  width: 75px;
}
.skills{
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}
/* } */